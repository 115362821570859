footer {
  /* margin-top: 80px; */
  border-top: solid 1px #e5e5e5;
  padding: 20px 15px; /* Adjust padding for smaller screens */
  display: grid;
  grid-template-columns: 1fr; /* Use a single column by default */
  background-color: #1b262c;
  color: white;

  /* Responsive layout for medium-sized screens */
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Responsive layout for larger screens */
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 20px 50px; /* Adjust padding for larger screens */
  }
}
