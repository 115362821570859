.room-detail-header-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.room-detail-header {
  color: #152c5b;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.rdrCalendarWrapper {
  width: 100% !important;
}
